body {
    background-color: #fff !important;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.grecaptcha-badge {
    display:none !important;
}

main {
    flex-grow: 1;
    height: 100%;
}

header, main, footer {
    flex-shrink: 0 !important;
}

#main-article {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline;
}

html {
    scroll-behavior: smooth;
}

html.normal-scroll {
    scroll-behavior: auto;
}
